.contact-center #num-section-1 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .contact-center #num-section-1 {
    background-position: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.contact-center #num-section-2 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .contact-center #num-section-2 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.contact-center #num-section-3 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .contact-center #num-section-3 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.contact-center #num-section-4 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .contact-center #num-section-4 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.contact-center #num-section-5 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .contact-center #num-section-5 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.contact-center #num-section-5 .img-class {
  max-width: 500px;
  display: block;
}

.contact-center #num-section-6 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .contact-center #num-section-6 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.contact-center #num-section-7 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .contact-center #num-section-7 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 768px) {
  .contact-center #num-section-7::before {
    background-image: url('../../assets/images/donut_img.png');
    background-size: initial;
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 500px;
    height: 500px;
    background-position: center right;
  }
}

.contact-center #num-section-8 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .contact-center #num-section-8 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.contact-center #num-section-9 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .contact-center #num-section-9 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

#num-section-9 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  #num-section-9 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}



@media (min-width: 768px) {
  .contact-center #num-section-9::after {
    background-image: url('../../assets/images/greater_then_img.png');
    background-size: initial;
    background-repeat: no-repeat;
    background-position: center left;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 500px;
    height: 800px;
    z-index: -1;
  }
}

.contact-center #num-section-10 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .contact-center #num-section-10 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.contact-center #num-section-11 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .contact-center #num-section-11 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.contact-center #num-section-12 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .contact-center #num-section-12 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.contact-center #num-section-13 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .contact-center #num-section-13 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.contact-center #num-section-13 .img-class {
  max-width: 500px;
  display: block;
}

.contact-center #num-section-14 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .contact-center #num-section-14 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.contact-center #num-section-15 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .contact-center #num-section-15 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.contact-center #num-section-16 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .contact-center #num-section-16 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.contact-center #num-section-17 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .contact-center #num-section-17 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.contact-center #num-section-18 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .contact-center #num-section-18 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.contact-center #num-section-19 {
  background-color: #5fab98;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .contact-center #num-section-19 {
    /* background-image: url(https://toku.co/wp-content/uploads/2022/11/bg_before_footer.jpeg); */
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.ul-v li:before {
  position: absolute;
  top: 0;
  left: -32px;
  content: "";
  width: 24px;
  height: 24px;
  background-image: url('../../assets/images/checked.svg');
  background-size: 24px 24px;
}