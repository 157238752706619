.crm #num-section-1 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .crm #num-section-1 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.crm #num-section-2 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .crm #num-section-2 {
    background-position: Boost Your Call Quality;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.crm #num-section-3 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .crm #num-section-3 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 768px) {
  .crm #num-section-3::after {
    background-image: url(https://toku.co/wp-content/uploads/2022/12/Frame-46311-3.png);
    background-size: initial;
    background-repeat: no-repeat;
    background-position: center left;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 500px;
    height: 800px;
    z-index: -1;
  }

  .crm #num-section-3::before {
    background-image: url(https://toku.co/wp-content/uploads/2022/12/right-mic.png);
    background-size: initial;
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 500px;
    height: 400px;
    background-position: center right;
    z-index: -1;
  }
}

.crm #num-section-4 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .crm #num-section-4 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.crm #num-section-5 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .crm #num-section-5 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.crm #num-section-6 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .crm #num-section-6 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.crm #num-section-7 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .crm #num-section-7 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.crm #num-section-8 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .crm #num-section-8 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.crm #num-section-9 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .crm #num-section-9 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.crm #num-section-10 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .crm #num-section-10 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.crm #num-section-11 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .crm #num-section-11 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.crm #num-section-11 {
}

.crm #num-section-12 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .crm #num-section-12 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.crm #num-section-13 {
  background-color: #5fab98;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .crm #num-section-13 {
    background-color: #5fab98;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
