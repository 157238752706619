




.home #num-section-1 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-1 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-2 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-2 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-3 {
  background-color: #7dbaab;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-3 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-3 {
  background-color: #7dbaab;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-3 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-3 .side-two {
  text-align: center;
}

.home #num-section-4 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-4 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-5 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-5 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-6 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-6 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-6 .img-class {
  max-width: 600px;
  display: block;
}

.home #num-section-7 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-7 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-8 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-8 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-9 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-9 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 768px) {
  .home #num-section-9::before {
    /* background-image: url(/wp-content/uploads/2022/12/right-mic.png); */
    background-size: initial;
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 500px;
    height: 500px;
    background-position: center right;
    z-index: +1;
  }
}

.home #num-section-10 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-10 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-10 img {
  max-width: 700px;
  display: block;
}

@media screen and (max-width: 768px) {
  .home #num-section-10 img {
    max-width: 100%;
  }
}

.home #num-section-11 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-11 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 768px) {
  .home #num-section-11::after {
    /* background-image: url(https://toku.co/wp-content/uploads/2022/12/Frame-46311-3.png); */
    background-size: initial;
    background-repeat: no-repeat;
    background-position: center left;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 500px;
    height: 800px;
    z-index: +1;
  }
}

.home #num-section-12 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-12 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-13 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-13 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-13 * {
  z-index: 2;
}

.home #num-section-14 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-14 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-15 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-15 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-16 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-16 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-16 img {
  max-width: 600px;
  display: block;
}

.home #num-section-17 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-17 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-18 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-18 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-19 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-19 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-19 .container {
  background: white;
  box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  max-width: 90%;
}

.home #num-section-20 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-20 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-21 {
  background-color: #5fab98;
  /* background-image: url(https://toku.co/wp-content/uploads/2023/01/Frame-46376.png); */
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-21 {
    background-color: #5fab98;
    /* background-image: url(https://toku.co/wp-content/uploads/2023/01/Frame-46375.png); */
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-21 .image-one img {
  top: 20px;
  position: relative;
}

.home #num-section-21 .image-two img {
  top: -20px;
  position: relative;
}


.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider
.slick-loading .slick-list {
  background: #fff url("../images/ajax-loader.gif") center center no-repeat;
} */
