.carrier #num-section-1 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .carrier #num-section-1 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.carrier #num-section-2 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .carrier #num-section-2 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.carrier #num-section-3 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .carrier #num-section-3 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.carrier #num-section-4 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .carrier #num-section-4 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.carrier #num-section-5 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .carrier #num-section-5 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.carrier #num-section-6 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .carrier #num-section-6 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.carrier #num-section-7 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .carrier #num-section-7 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.carrier #num-section-8 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .carrier #num-section-8 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.carrier #num-section-9 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .carrier #num-section-9 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.carrier  .section-icon_boxes .box {
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px 5px rgba(7, 20, 63, 0.1);
    border-radius: 3px;
    height: 100%;
}

@media (max-width: 991px) {
    .carrier  .section-icon_boxes .box {
        margin: 0 auto;
        max-width: 350px;
    }

    .carrier  .section-icon_boxes .mobile-icon img {
        height: 84px;
    }
}

@media (min-width: 1024px) {
    .carrier  .section-icon_boxes .box {
        max-width: 510px;
    }
}

.carrier  .section-icon_boxes .icon {
    /*flex: 0 0 170px;*/
    width: 170px;
    text-align: center;
    height: 100%;
    align-items: center;
    display: flex;
    max-width: 30%;
}

.carrier  .section-icon_boxes .content {
    width: 100%;
}

@media (max-width: 991px) {
    .carrier  .section-icon_boxes .content {
        margin-top: -45px;
    }
}

@media (min-width: 992px) {
    .carrier  .section-icon_boxes .content {
        width: calc(100% - 170px);
    }
}

.carrier .section-icon_boxes .container {
    max-width: 1070px;
}

.carrier #num-section-10 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .carrier #num-section-10 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.carrier #num-section-11 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .carrier #num-section-11 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.carrier #num-section-12 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .carrier #num-section-12 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.carrier #num-section-13 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .carrier #num-section-13 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.carrier #num-section-14 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .carrier #num-section-14 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.carrier #num-section-15 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .carrier #num-section-15 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.carrier #num-section-16 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .carrier #num-section-16 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.carrier #num-section-17 {
    /* background-image: url(https://toku.co/wp-content/uploads/2023/01/Frame-46394.png); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .carrier #num-section-17 {
        /* background-image: url(https://toku.co/wp-content/uploads/2023/01/Frame-46393.png); */
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.carrier #num-section-17 .style-h2 {
    max-width: 456px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px !important;
}


@media (max-width: 767px) {
    .carrier #num-section-17 {
        padding-top: 200px;
        padding-bottom: 180px;
    }
}