

.header-hero{
  background-color: #7DBAAB;
  z-index: 999 !important;
}

.hero-contact_center {
  background-image: url('../../assets/images/firstsection-background1.png');
}
.btn-yellow{
  padding: 14px 24px;
}
.navbar-login-button{
  background-color: #E6007E;
  margin-left: 15px;
}
.navbar-login-button:hover{
  background-color: #E6007E !important;
  filter: brightness(110%);
}
.integration-logo{
  width: 34px;
}
@media ( min-width: 768px ) {
  .hero-contact_center {
    background-image: url('../../assets/images/firstsection-background1.png');
  }
}



