.slider{
  
    padding: 3px 10px;
  }
  .slide-item {
    display: inline-block;
    animation: slide-left 30s linear infinite;
  }
  
  @keyframes slide-left {
    0%{
        transform: translate(100%);
    }
  
    /* 50% {
      transform: translate(-50%);
    } */
    
    100% {
        transform: translateX(-150%);
      }
     
      /* 50%{
        transform: translateX(-10%);
      } */
      /* 100%{
        transform: translateX(-0%);
      } */
      /* 50% {
        transform: translateX(10%);
      } */
     
      /* 90% {
        transform: translateX(-90%);
      }
      100%{
        transform: translate(-100%);
      } */
  }


  @media only screen and (max-width: 300px) {
    .slide-item {
      display: inline-block;
      animation: slide-left 10s linear infinite;
    }
  }

  .slide-item a{
    color: black;
    opacity: 0.7;
    padding-right: 50px;
  }
  .slide-item a:hover{
    text-decoration: none;
  }