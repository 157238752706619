footer {
  background-color: #3f5d56;
}

footer ul li {
  margin-top: 20px;
  font-size: 14px;
  text-decoration: none !important;
}
.text-color {
  color: #efefef;
}
a:-webkit-any-link {
  text-decoration: none;
}
.footer-h5 h5 {
  color: #7dbaab;
  font-weight: 400;
}
.bottom-footer {
  height: 90px;
  align-items: center;
  margin-top: 50px;
  border-top: 1px solid white;
}
.soical-icon {
  float: right;
}
.soical-icon i {
  margin-left: 15px;
  font-size: 30px;
}
