.hostpitality #num-section-1 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .hostpitality #num-section-1 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.hostpitality #num-section-2 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .hostpitality #num-section-2 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.hostpitality #num-section-3 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .hostpitality #num-section-3 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.hostpitality #num-section-4 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .hostpitality #num-section-4 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.hostpitality #num-section-5 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .hostpitality #num-section-5 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.hostpitality #num-section-5 .strip-counter-header * {
  color: #7dbaab;
}

.f-120 {
  font-size: 120px;
  line-height: 110%;
}

.hostpitality #num-section-6 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .hostpitality #num-section-6 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.hostpitality #num-section-7 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .hostpitality #num-section-7 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.hostpitality #num-section-7 .img-class {
  max-width: 600px;
  display: block;
}

.hostpitality #num-section-8 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .hostpitality #num-section-8 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.hostpitality #num-section-8 .img-class {
  max-width: 600px;
  display: block;
}

.hostpitality #num-section-9 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .hostpitality #num-section-9 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.hostpitality #num-section-9 .img-class {
  max-width: 600px;
  display: block;
}

.hostpitality #num-section-10 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .hostpitality #num-section-10 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.hostpitality #num-section-10 .img-class {
  max-width: 600px;
  display: block;
}

.hostpitality #num-section-11 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .hostpitality #num-section-11 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.hostpitality #num-section-11 .img-class {
  max-width: 600px;
  display: block;
}

.hostpitality #num-section-12 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .hostpitality #num-section-12 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.hostpitality #num-section-13 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .hostpitality #num-section-13 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.hostpitality #num-section-14 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .hostpitality #num-section-14 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.hostpitality #num-section-15 {
  background-color: #7dbaab;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .hostpitality #num-section-15 {
    background-color: #7dbaab;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.hostpitality #num-section-15 .image-one img {
  top: 20px;
  position: relative;
}

.hostpitality #num-section-15 .image-two img {
  top: -20px;
  position: relative;
}
.hostpitality
  .section-2_items_in_row_with_image_title_text_link.style-slimmer
  .image
  img {
  width: 60px;
}
