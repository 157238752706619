.number-masking #num-section-1 {
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .number-masking #num-section-1 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.number-masking #num-section-1 {
}

.number-masking #num-section-2 {
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .number-masking #num-section-2 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.number-masking #num-section-2 {
}

.number-masking #num-section-3 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .number-masking #num-section-3 {
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.number-masking #num-section-3 {
}

.number-masking #num-section-4 {
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .number-masking #num-section-4 {
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.number-masking #num-section-4 {
}

.number-masking #num-section-5 {
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .number-masking #num-section-5 {
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.number-masking #num-section-5 {
}

.number-masking #num-section-6 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .number-masking #num-section-6 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.number-masking #num-section-6 {
}

.number-masking #num-section-7 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .number-masking #num-section-7 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.number-masking #num-section-7 {
}

.number-masking #num-section-8 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .number-masking #num-section-8 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.number-masking #num-section-8 {
}

.number-masking #num-section-9 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .number-masking #num-section-9 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.number-masking #num-section-11 {
  background-color: #7dbaab;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .number-masking #num-section-11 {
    background-color: #7dbaab;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.number-masking #num-section-11 .img-class {
  max-width: 400px;
  display: block;
}
