.careers #num-section-1 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .careers #num-section-1 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.careers #num-section-2 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .careers #num-section-2 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.careers #num-section-2 p {
    max-width: 800px;
    margin: 0 auto;
}

.careers #num-section-3 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .careers #num-section-3 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.careers #num-section-4 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .careers #num-section-4 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.careers #num-section-4 .container {
    background: #010E39;
    border-radius: 20px;
}

.careers #num-section-5 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .careers #num-section-5 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

@media (min-width: 768px) {
    .careers #num-section-5::before {
        /* background-image: url(/wp-content/uploads/2022/12/right-mic.png); */
        background-size: initial;
        background-repeat: no-repeat;
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 500px;
        height: 500px;
        background-position: center right;
    }
}

.careers #num-section-6 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .careers #num-section-6 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.careers #num-section-7 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .careers #num-section-7 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

@media (min-width: 768px) {
    .careers #num-section-7::after {
        /* background-image: url(https://toku.co/wp-content/uploads/2022/12/Frame-46311-3.png); */
        background-size: initial;
        background-repeat: no-repeat;
        background-position: top left;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 500px;
        height: 800px;
        z-index: -1;
    }
}

.careers #num-section-8 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .careers #num-section-8 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.careers #num-section-9 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .careers #num-section-9 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.careers #num-section-10 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .careers #num-section-10 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.careers #num-section-10 .img-class {
    max-width: 600px;
    display: block;
}

.careers #num-section-11 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .careers #num-section-11 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.careers #num-section-12 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .careers #num-section-12 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.careers #num-section-13 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .careers #num-section-13 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.careers #num-section-14 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .careers #num-section-14 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.careers #num-section-15 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .careers #num-section-15 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.careers #num-section-16 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .careers #num-section-16 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.careers #num-section-17 {
    /* background-image: url(https://toku.co/wp-content/uploads/2023/01/Frame-46352.png); */
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .careers #num-section-17 {
        /* background-image: url(https://toku.co/wp-content/uploads/2023/01/Frame-46353.png); */
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.careers #num-section-17 .row {
    padding-bottom: 0 !important;
}