.edutech #num-section-2 {
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media (min-width: 768px) {
    .edutech #num-section-2 {
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .edutech #num-section-2 {
  }
  .edutech #num-section-3 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media (min-width: 768px) {
    .edutech #num-section-3 {
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .edutech #num-section-3 {
  }
  .edutech #num-section-4 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media (min-width: 768px) {
    .edutech #num-section-4 {
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .edutech #num-section-4 {
  }
  .edutech #num-section-5 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media (min-width: 768px) {
    .edutech #num-section-5 {
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .edutech #num-section-5 .strip-counter-header * {
    color: #7dbaab;
  }
  .f-120 {
    font-size: 120px;
    line-height: 110%;
  }
  .edutech #num-section-6 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media (min-width: 768px) {
    .edutech #num-section-6 {
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .edutech #num-section-6 {
  }
  .edutech #num-section-7 {
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media (min-width: 768px) {
    .edutech #num-section-7 {
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .edutech #num-section-7 .img-class {
    max-width: 600px;
    display: block;
  }
  .edutech #num-section-8 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media (min-width: 768px) {
    .edutech #num-section-8 {
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .edutech #num-section-8 .img-class {
    max-width: 600px;
    display: block;
  }
  .edutech #num-section-9 {
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media (min-width: 768px) {
    .edutech #num-section-9 {
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .edutech #num-section-9 .img-class {
    max-width: 600px;
    display: block;
  }
  .edutech #num-section-10 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media (min-width: 768px) {
    .edutech #num-section-10 {
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .edutech #num-section-10 .img-class {
    max-width: 600px;
    display: block;
  }
  
  .edutech #num-section-11 {
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media (min-width: 768px) {
    .edutech #num-section-11 {
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .edutech #num-section-11 .img-class {
    max-width: 600px;
    display: block;
  }
  .edutech #num-section-12 {
    background-color: #7dbaab;
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media (min-width: 768px) {
    .edutech #num-section-12 {
      background-color: #7dbaab;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .edutech #num-section-12 .image-one img {
    top: -20px;
    position: relative;
  }
  
  .edutech #num-section-12 .image-two img {
    top: 20px;
    position: relative;
  }
  .edutech .top-title {
    color: white;
    background-color: #652580;
  }
  