.resource #num-section-1 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .resource #num-section-1 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.resource #num-section-2 {
  background-color: #5fab98;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .resource #num-section-2 {
    background-color: #5fab98;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.resource #num-section-2 .row {
  padding-bottom: 0 !important;
}
