.zoom #num-section-1 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .zoom #num-section-1 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.zoom #num-section-1 {
}

.zoom #num-section-2 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .zoom #num-section-2 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.zoom #num-section-2 {
}

.zoom #num-section-3 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .zoom #num-section-3 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.zoom #num-section-3 {
}

.zoom #num-section-4 {
  background-color: #f1f5fe;
  background-image: url(https://toku.co/wp-content/uploads/2022/11/map-vector.png);
  background-position: bottom center;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .zoom #num-section-4 {
    background-image: url(https://toku.co/wp-content/uploads/2022/11/map-bg-img.png);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.zoom #num-section-4 {
}

.zoom #num-section-5 {
  background-color: #5fab98;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .zoom #num-section-5 {
    background-color: #5fab98;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.zoom #num-section-5 .image-one img {
  top: 20px;
  position: relative;
}

.zoom #num-section-5 .image-two img {
  top: -20px;
  position: relative;
}
