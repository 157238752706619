.fin-tech #num-section-1 {
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .fin-tech #num-section-1 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.fin-tech #num-section-3 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .fin-tech #num-section-3 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.fin-tech #num-section-5 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .fin-tech #num-section-5 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.fin-tech #num-section-5 .strip-counter-header * {
  color: #7dbaab;
}
.fin-tech .f-120 {
  font-size: 120px;
  line-height: 110%;
}
.fin-tech #num-section-6 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .fin-tech #num-section-6 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.fin-tech #num-section-7 {
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .fin-tech #num-section-7 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.fin-tech #num-section-7 .img-class {
  max-width: 600px;
  display: block;
}
.fin-tech #num-section-8 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .fin-tech .top-title {
    color: white;
    background-color: #652580;
  }

  .fin-tech #num-section-8 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.fin-tech #num-section-8 .img-class {
  max-width: 600px;
  display: block;
}

.fin-tech #num-section-9 {
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .fin-tech #num-section-9 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.fin-tech #num-section-9 .img-class {
  max-width: 600px;
  display: block;
}
.fin-tech #num-section-10 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .fin-tech #num-section-10 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.fin-tech #num-section-10 .img-class {
  max-width: 600px;
  display: block;
}
.fin-tech #num-section-11 {
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .fin-tech #num-section-11 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.fin-tech #num-section-11 {
}
.fin-tech #num-section-12 {
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .fin-tech #num-section-12 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.fin-tech #num-section-14 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .fin-tech #num-section-14 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.fin-tech #num-section-15 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .fin-tech #num-section-15 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.fin-tech #num-section-16 {
  background-color: #7dbaab;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .fin-tech #num-section-16 {
    background-color: #7dbaab;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.fin-tech #num-section-16 .image-one img {
  top: 20px;
  position: relative;
}

.fin-tech #num-section-16 .image-two img {
  top: -20px;
  position: relative;
}
