.programmable-messaging #num-section-1 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programmable-messaging #num-section-1 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programmable-messaging #num-section-1 {
}

.programmable-messaging #num-section-2 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programmable-messaging #num-section-2 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programmable-messaging #num-section-2 {
}

.programmable-messaging #num-section-3 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programmable-messaging #num-section-3 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programmable-messaging #num-section-3 {
}

.programmable-messaging #num-section-4 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programmable-messaging #num-section-4 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programmable-messaging #num-section-4 {
}

.programmable-messaging #num-section-5 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programmable-messaging #num-section-5 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programmable-messaging #num-section-5 .strip-counter-header * {
  color: #7dbaab;
}

.f-120 {
  font-size: 120px;
  line-height: 110%;
}

.programmable-messaging #num-section-6 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programmable-messaging #num-section-6 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programmable-messaging #num-section-6 {
}

.programmable-messaging #num-section-7 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programmable-messaging #num-section-7 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programmable-messaging #num-section-7 {
}

.programmable-messaging #num-section-8 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programmable-messaging #num-section-8 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programmable-messaging #num-section-8 {
}

.programmable-messaging #num-section-9 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programmable-messaging #num-section-9 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programmable-messaging #num-section-9 {
}

.programmable-messaging #num-section-10 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programmable-messaging #num-section-10 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programmable-messaging #num-section-10 {
}

.programmable-messaging #num-section-11 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programmable-messaging #num-section-11 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programmable-messaging #num-section-11 {
}

.programmable-messaging #num-section-12 {
  background-color: #5fab98;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programmable-messaging #num-section-12 {
    background-color: #5fab98;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programmable-messaging #num-section-12 .img-class {
  max-width: 300px;
  display: block;
  margin: 0 auto 20px auto;
}
