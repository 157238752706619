.retail #num-section-1 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .retail #num-section-1 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.retail #num-section-1 {
}

.retail #num-section-2 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .retail #num-section-2 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.retail #num-section-3 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .retail #num-section-3 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.retail #num-section-3 {
}

.retail #num-section-4 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .retail #num-section-4 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.retail #num-section-4 {
}

.retail #num-section-5 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .retail #num-section-5 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.retail #num-section-5 .strip-counter-header * {
  color: #7dbaab;
}

.retail .f-120 {
  font-size: 120px;
  line-height: 110%;
}

.retail #num-section-6 {
  -moz-background-clip: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .retail #num--moz-background-clipsection-6 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.retail #num-section-7 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .retail #num-section-7 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.retail #num-section-7 .img-class {
  max-width: 600px;
  display: block;
}

.retail #num-section-8 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .retail #num-section-8 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.retail #num-section-8 .img-class {
  max-width: 600px;
  display: block;
}

.retail #num-section-9 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .retail #num-section-9 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.retail #num-section-9 .img-class {
  max-width: 600px;
  display: block;
}

.retail #num-section-10 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .retail #num-section-10 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.retail #num-section-11 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .retail #num-section-11 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.retail #num-section-12 {
  background-color: #f1f5fe;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .retail #num-section-12 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.retail #num-section-13 {
  background-color: #5fab98;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .retail #num-section-13 {
    background-color: #5fab98;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.retail #num-section-13 .image-one img {
  top: 20px;
  position: relative;
}

.retail #num-section-13 .image-two img {
  top: -20px;
  position: relative;
}

.retail *,
*::before,
*::after {
  box-sizing: border-box;
}
.retail
  .section-2_items_in_row_with_image_title_text_link.style-slimmer
  .image
  img {
  width: 60px;
}
