h2,
h3,
h4 {
  padding-bottom: 1rem;
}
.wp-caption-text {
  text-align: center;
}
.fullstory .section-cta_strip_2 {
  background-color: #7ABAAB;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .fullstory .section-cta_strip_2 {
   background-color: #7ABAAB;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

