.why-kasookoo #num-section-1 {
  /* background-color: #f1f5fe; */
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-1 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.why-kasookoo #num-section-1 {
}

.why-kasookoo #num-section-2 {
  /* background-color: #f1f5fe; */
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-2 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.why-kasookoo #num-section-2 {
}

.why-kasookoo #num-section-3 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-3 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.why-kasookoo #num-section-3 {
}

.why-kasookoo #num-section-4 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-4 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.why-kasookoo #num-section-4 {
}

.why-kasookoo #num-section-5 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-5 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.why-kasookoo #num-section-5 {
}

.why-kasookoo #num-section-6 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-6 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.why-kasookoo #num-section-6 {
}

.why-kasookoo #num-section-7 {
  background-color: #3c3c3b;
  background-position: #3c3c3b;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-7 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.why-kasookoo #num-section-7 {
}

.why-kasookoo #num-section-8 {
  background-color: #3c3c3b;
  background-position: #3c3c3b;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-8 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-8::before {
    background-image: url(https://toku.co/wp-content/uploads/2022/12/Frame-46346.png);
    background-size: initial;
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 500px;
    height: 500px;
    background-position: top right;
    z-index: 1;
  }
}

.why-kasookoo #num-section-9 {
  background-color: #3c3c3b;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-9 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.why-kasookoo #num-section-9 p {
  color: #fff;
}

.why-kasookoo #num-section-10 {
  background-color: #3c3c3b;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-10 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.why-kasookoo #num-section-10 * {
  color: #fff;
}

.f-120 {
  font-size: 120px;
  line-height: 110%;
}

.why-kasookoo #num-section-11 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-11 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.why-kasookoo #num-section-11 {
}

.why-kasookoo #num-section-12 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-12 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-12::after {
    background-image: url(https://toku.co/wp-content/uploads/2022/12/left-mic.png);
    background-size: initial;
    background-repeat: no-repeat;
    background-position: bottom left;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 500px;
    height: 500px;
  }
}

.why-kasookoo #num-section-13 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-13 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.why-kasookoo #num-section-13 {
}

.why-kasookoo #num-section-14 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-14 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-14::before {
    background-image: url(https://toku.co/wp-content/uploads/2022/12/right-mic.png);
    background-size: initial;
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 500px;
    height: 500px;
    background-position: center right;
  }
}

.why-kasookoo #num-section-15 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-15 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-15::after {
    background-image: url(https://toku.co/wp-content/uploads/2022/12/Frame-46311-3.png);
    background-size: initial;
    background-repeat: no-repeat;
    background-position: center left;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 500px;
    height: 800px;
    z-index: -1;
  }
}

.why-kasookoo #num-section-16 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-16 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.why-kasookoo #num-section-17 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-17 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.why-kasookoo #num-section-17 {
}

.why-kasookoo #num-section-18 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-18 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.why-kasookoo #num-section-18 {
}

.why-kasookoo #num-section-19 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-19 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.why-kasookoo #num-section-19 {
}

.why-kasookoo #num-section-20 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-20 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.why-kasookoo #num-section-20 .container {
  background: white;
  box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  max-width: 90%;
}

.why-kasookoo #num-section-21 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-21 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.why-kasookoo #num-section-21 {
}

.why-kasookoo #num-section-22 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-22 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.why-kasookoo #num-section-22 {
}

.why-kasookoo #num-section-23 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-23 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.why-kasookoo #num-section-23 {
}

.why-kasookoo #num-section-24 {
  background-image: url('../../assets/images/Group 1188.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .why-kasookoo #num-section-24 {
    background-image: url('../../assets/images/Group 1188.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: -22px;
  }
}

.why-kasookoo #num-section-24 .style-h2 {
  max-width: 456px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 30px !important;
}





