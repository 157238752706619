.about-us #num-section-2 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .about-us #num-section-2 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.about-us #num-section-2 {}

.about-us #num-section-3 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .about-us #num-section-3 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.about-us #num-section-3 {}

.about-us #num-section-4 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .about-us #num-section-4 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.about-us #num-section-4 * {
  height: auto;
  padding: 0;
}

.about-us #num-section-5 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .about-us #num-section-5 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.about-us #num-section-5 {}

.about-us #num-section-6 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .about-us #num-section-6 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.about-us #num-section-6 {}

.about-us #num-section-7 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .about-us #num-section-7 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.about-us #num-section-7 {}

.about-us .section-image_on_the_left_and_list_items_on_the_right .box-item {
  /* max-width: 410px; */
  margin: 0 auto 15px;
}

.about-us #core-values {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  #core-values {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.about-us #core-values {}


.about-us #num-section-9 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .about-us #num-section-9 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.about-us #num-section-9 {}

.about-us #num-section-10 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .about-us #num-section-10 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.about-us #num-section-10 {}

.about-us .section-icon_boxes .box {
  display: flex;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px 5px rgba(7, 20, 63, 0.1);
  border-radius: 3px;
  height: 100%;
}

@media (max-width: 991px) {
  .about-us .section-icon_boxes .box {
    margin: 0 auto;
    max-width: 350px;
  }

  .about-us .section-icon_boxes .mobile-icon img {
    height: 84px;
  }
}

@media (min-width: 1024px) {
  .about-us .section-icon_boxes .box {
    max-width: 510px;
  }
}

.about-us .section-icon_boxes .icon {
  /*flex: 0 0 170px;*/
  width: 170px;
  text-align: center;
  height: 100%;
  align-items: center;
  display: flex;
  max-width: 30%;
}

.about-us .section-icon_boxes .content {
  width: 100%;
}

@media (max-width: 991px) {
  .about-us .section-icon_boxes .content {
    margin-top: -45px;
  }
}

@media (min-width: 992px) {
  .about-us .section-icon_boxes .content {
    width: calc(100% - 170px);
  }
}

.about-us .section-icon_boxes .container {
  max-width: 1070px;
}

.about-us #num-section-11 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .about-us #num-section-11 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.about-us #num-section-11 {}

.about-us #num-section-12 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .about-us #num-section-12 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.about-us #num-section-12 {}

.about-us #num-section-13 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .about-us #num-section-13 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.about-us #num-section-13 {}

.about-us #num-section-14 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .about-us #num-section-14 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.about-us #num-section-14 {}

.about-us #num-section-15 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .about-us #num-section-15 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.about-us #num-section-15 {}

.about-us #num-section-16 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .about-us #num-section-16 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.about-us #num-section-16 {}

.about-us #num-section-17 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .about-us #num-section-17 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.about-us #num-section-17 .tm-photo img {
  height: 250px;
}

.about-us .team-member .description {
  display: none;
}

.about-us .team-member.active .description {
  display: block !important;
}

.about-us .team-member .view-caret {
  transition: 0.5s all;
}

.about-us .team-member.active .view-caret {
  rotate: 180deg;
}

.about-us .about-us #num-section-18 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .about-us #num-section-18 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.about-us #num-section-18 {}

.about-us #num-section-19 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .about-us #num-section-19 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.about-us #num-section-19 {}

.about-us #num-section-20 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .about-us #num-section-20 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.about-us #num-section-20 {}

.about-us #num-section-21 {
  /* background: transparent linear-gradient(105deg,
      #b3ebcd 0%,
      #ace6cc 12%,
      #9bdac9 29%,
      #7ec7c6 48%,
      #56acc1 69%,
      #3696bd 84%) 0% 0%; */
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .about-us #num-section-21 {
    /* background: transparent linear-gradient(105deg,
        #b3ebcd 0%,
        #ace6cc 12%,
        #9bdac9 29%,
        #7ec7c6 48%,
        #56acc1 69%,
        #3696bd 84%) 0% 0% no-repeat padding-box; */
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.about-us #num-section-21 .row {
  padding-bottom: 0 !important;
}