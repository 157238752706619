.contact-from{
    position: fixed;
    min-height: 100vh;
    min-width: 100vw;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    top : 0;
    opacity: 1;
}

.circle{
    height: 15px;
    aspect-ratio: 1/1;
    background-color: #938c8c;
    border-radius: 50%;
    margin: 3px;
}
.current{
    background-color: #7DBAAB;
}

.contact-from input,.contact-from select{
    height: 35px;
    border: none;
    border-bottom: 1px solid black;
    padding: 5px;
}

.contact-btn{
    background-color: #652580;
    width: 100%;
    padding: 5px !important;
    border-radius: 20px;
}

.contact-form1 .row{
    margin: 0px;
    padding: 0 !important;
}

.contact-form1 .col-5{
    padding: 0 !important;
}
.contact-form1 .col{
    padding: 0 !important;
}
.contact-form1 button{
    border: none;
    color: white !important;
}

.contact-form1 .row{
    margin: 0px;
    padding: 0 !important;
}