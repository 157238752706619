.user-verification #num-section-1 {
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .user-verification #num-section-1 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.user-verification #num-section-1 {
}
.user-verification #num-section-2 {
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .user-verification #num-section-2 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.user-verification #num-section-2 {
}
.user-verification #num-section-3 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .user-verification #num-section-3 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.user-verification #num-section-3 {
}
.user-verification #num-section-4 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .user-verification #num-section-4 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.user-verification #num-section-4 * {
  z-index: 1;
}

@media (min-width: 768px) {
  .user-verification #num-section-4::after {
    background-image: url(https://toku.co/wp-content/uploads/2022/12/Frame-46311-3.png);
    background-size: initial;
    background-repeat: no-repeat;
    background-position: bottom left;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 500px;
    height: 900px;
    z-index: 0;
  }
  .user-verification #num-section-4::before {
    background-image: url(https://toku.co/wp-content/uploads/2022/12/right-mic.png);
    background-size: initial;
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 500px;
    height: 500px;
    background-position: center right;
    z-index: 0;
  }
}

.user-verification #num-section-5 {
  background-color: #5fab98;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .user-verification #num-section-5 {
    background-color: #5fab98;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.user-verification #num-section-5 .image-one img {
  top: -20px;
  position: relative;
}

.user-verification #num-section-5 .image-two img {
  top: 20px;
  position: relative;
}
