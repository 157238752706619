.programvoice .num-hero-1 {
}

.programvoice .hero-programmable_voice {
  background-image: url(https://toku.co/wp-content/uploads/2022/11/bg-hero-2-mobile.png);
}

@media (min-width: 768px) {
  .programvoice .hero-programmable_voice {
    background-image: url(https://toku.co/wp-content/uploads/2022/11/bg-hero-2-desktop.png);
  }
}

.programvoice #num-section-1 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programvoice #num-section-1 {
    background-size: cover;
    background-repeat: no-repeat;
    align-content: center;
  }
}

.programvoice
  .section-recommendations_slider
  blockquote.style-turquoise:before {
  background-image: url(../../assets/images/quote-start-turquoise.svg);
  background-image: url(../../assets/images/quote-start-turquoise.svg);
  top: 0;
  right: auto;
  left: -10;
}

.programvoice .section-recommendations_slider blockquote:before,
.programvoice .section-recommendations_slider blockquote:after {
  content: "";
  width: 42px;
  height: 36px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  z-index: 1;
}

.programvoice .section-recommendations_slider blockquote:after {
  background-image: url(../../assets/images/quote-end-turquoise.svg);
  bottom: 0;
  right: 0;
  top: 0;
  left: auto;
}

@media (min-width: 768px) {
  .programvoice .section-recommendations_slider blockquote:after {
    top: 20px;
    right: -90px;
    left: auto;
  }

  .programvoice .section-recommendations_slider blockquote:before {
    top: 20px;
    right: auto;
    left: -90px;
    width: 63px;
    height: 54px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.programvoice #num-section-2 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

.programvoice #num-section-2 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programvoice #num-section-2 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programvoice #num-section-3 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programvoice #num-section-3 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programvoice #num-section-4 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programvoice #num-section-4 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programvoice #num-section-5 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programvoice #num-section-5 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programvoice #num-section-6 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programvoice #num-section-6 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programvoice #num-section-7 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programvoice #num-section-7 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programvoice #num-section-8 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programvoice #num-section-8 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programvoice #num-section-9 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programvoice #num-section-9 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programvoice #num-section-10 {
  background-color: #5fab98;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programvoice #num-section-10 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programvoice #num-section-11 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programvoice #num-section-11 {
    background-image: url(https://toku.co/wp-content/uploads/2022/11/Group-1000004418.jpeg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programvoice #num-section-12 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programvoice #num-section-12 {
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.programvoice #num-section-13 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programvoice #num-section-13 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programvoice #num-section-14 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programvoice #num-section-14 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programvoice #num-section-15 {
  background-color: #5fab98;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .programvoice #num-section-15 {
    background-color: #5fab98;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.programvoice #num-section-15 .img-class {
  max-width: 400px;
  display: block;
}

.programvoice .slide {
  background-color: white;
}
.voice-calling #num-section-8 img {
  height: auto;
}
